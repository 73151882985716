<script>
import Action from '@/components/Action'
import Icon from '@/components/Icon'

export default {
  name: 'Order',

  components: {
    Action,
    Icon,
  },

  props: {
    id: {
      type: [String, Number],
      default: null,
    },

    name: {
      type: String,
      default: null,
    },

    total: {
      type: Number,
      default: null,
    },

    status: {
      type: String,
      default: null,
    },

    status_web: {
      type: String,
      default: null,
    },

    updated: {
      type: String,
      default: null,
    },

    viewType: {
      type: String,
      default: 'block',
    },

  },

  computed: {
    computedClasses () {
      return {
        [this.$classes['waiting']]: this.status_web === 'Preparando',
        [this.$classes['deleted']]: this.status_web === 'Encerrado',
        [this.$classes['generated']]: this.status_web === 'Pedido Gerado',
        [this.$classes['failed']]: this.status_web === 'Falha no Envio',
        [this.$classes['canceled']]: this.status_web === 'Cancelado',
        [this.$classes['fail-import']]: this.status_web === 'Falha na Importação Automática',
      }
    },

    canCheckout () {
      return !!this.total && this.status === 'Em andamento'
    },

    orderDetailRoute () {
      return {
        name: 'orders.details',
        params: {
          id: this.id,
        },
      }
    },
  },

  methods: {
    handleCheckoutOrder () {
      this.$emit('finish')
    },
  },
}
</script>

<template>
  <div :class="[$classes['order'], {
    [$classes['order-block']]: viewType === 'block',
    [$classes['order-line']]: viewType === 'line'
  }]">
    <template v-if="viewType === 'block'">
      <span :class="$classes['order-number-label']">Carrinho</span>
      <span :class="$classes['order-number-value']">{{ name }}</span>
      <div :class="$classes['order-info']">
        <div :class="$classes['order-info-item']">
          <span :class="$classes['order-info-label']">Total</span>
          <span :class="$classes['order-info-value']">{{ total | currency }}</span>
        </div>
        <div :class="$classes['order-info-item']">
          <span :class="$classes['order-info-label']">Status Carrinho</span>
          <span :class="$classes['order-info-value']">{{ status }}</span>
        </div>
        <div v-if="status_web != 'Inserindo'" :class="$classes['order-info-item']">
          <span :class="$classes['order-info-label']">Status Pedido</span>
          <span :class="[$classes['order-info-value'], computedClasses]">{{ status_web }}</span>
        </div>
        <div :class="$classes['order-info-item']">
          <span :class="$classes['order-info-label']">Últ. atualização</span>
          <span :class="$classes['order-info-value']">{{ formatDate(updated) }}</span>
        </div>
      </div>
      <div :class="$classes['order-actions']">
        <action color="primary" variant="flat" :to="orderDetailRoute" uppercase bold
          :class="$classes['order-actions-btn']">
          <icon name="eye" btnIcon />
          Ver mais
        </action>
        <action color="tertiary" variant="flat" uppercase bold :disabled="!canCheckout"
          :class="$classes['order-actions-btn']" @click="handleCheckoutOrder">
          <icon name="share" btnIcon />
          Finalizar
        </action>
      </div>
    </template>
    <template v-else-if="viewType === 'line'">
      <span :class="$classes['order-number-value']">{{ name }}</span>
      <span :class="$classes['order-info-value']">{{ formatDate(updated) }}</span>
      <span :class="$classes['order-info-value']">{{ total | currency }}</span>
      <span :class="$classes['order-info-value']">{{ status }}</span>
      <action color="primary" variant="flat" :to="orderDetailRoute" bold :class="$classes['order-actions-btn']">
        <icon name="eye" />
      </action>
      <action color="tertiary" variant="flat" bold :disabled="!canCheckout" :class="$classes['order-actions-btn']"
        @click="handleCheckoutOrder">
        <icon name="share" />
      </action>
    </template>
  </div>
</template>

<style module>
.order-block {
  -webkit-box-flex: 1;
  display: grid;
  flex: 1;
  background: #fff;
  padding: 30px 30px 15px 30px;
  max-width: 436px;
  min-width: 422px;
  height: 373px;
}

.order-number-label {
  display: block;
  font-size: 12px;
  font-weight: bold;
  color: rgba(41, 41, 41, 0.8);
  letter-spacing: 0.1em;
  margin-bottom: 10px;
}

.order-number-value {
  display: block;
  font-size: 14px;
  font-weight: bold;
  color: rgba(41, 41, 41, 0.8);
  letter-spacing: 0.1em;
}

.order-info {
  margin-top: 30px;
}

.order-info-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.order-info-item+.order-info-item {
  margin-top: 25px;
}

.order-info-label {
  color: #a1a1a1;
}

.order-info-value {
  color: #292929;
}

.order-info-value.waiting {
  color: #002DB6;
  font-weight: bold;
}

.order-info-value.deleted {
  color: #8B4513;
  font-weight: bold;
}

.order-info-value.generated {
  color: #40CD28;
  font-weight: bold;
}

.order-info-value.failed {
  color: #FFA500;
  font-weight: bold;
}

.order-info-value.canceled {
  color: #AB0000;
  font-weight: bold;
}

.order-info-value.fail-import {
  color: #a100a1;
  font-weight: bold;
  text-align: end;
}

.order-actions {
  margin-top: 45px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.order-line {
  background: #fff;
  display: flex;
  align-items: center;
  height: 60px;
  padding: 0 30px;
  margin-bottom: 1px;
}

.order-line .order-number-value {
  flex: 1;
}

.order-line .order-info-value {
  font-size: 14px;
  text-transform: uppercase;
  flex: 1;
  max-width: 180px;
}

.order-line .order-actions-btn {
  width: 54px;
  height: 43px;
}

@media (max-width: 767px) {
  .order {
    height: auto;
    min-width: auto;
    max-width: auto;
    margin-right: 0;
  }
}

@media (max-width: 374px) {
  .order-actions {
    flex-direction: column;
  }

  .order-actions-btn+.order-actions-btn {
    margin-top: 15px;
  }
}
</style>
