<script>
import Modal from '@/components/Modal'
import Action from '@/components/Action'
import Icon from '@/components/Icon'
import logger from '@/logger/index'
import { format } from 'date-fns'

export default {
  name: 'ModalSideBar',

  components: {
    Modal,
    Action,
    Icon,
  },

  props: {
    active: {
      type: Boolean,
      default: false,
    },
    lastDay: {
      type: Number,
      default: 30,
    },
  },

  data () {
    return {
      selectedLastDay: 30,
      range: {
        start: new Date(new Date().setDate(new Date().getDate() - this.lastDay)),
        end: new Date(),
      },
    }
  },

  computed: {
    menu () {
      return [
        {
          day: 'Hoje',
          value: 0,
        },
        {
          day: '15 dias',
          value: 15,
        },
        {
          day: '30 dias',
          value: 30,
        },
        {
          day: '60 dias',
          value: 60,
        },
        {
          day: '90 dias',
          value: 90,
        }
      ]
    },
  },

  methods: {
    closeModalsidebar () {
      this.$emit('close')
    },

    changeLastDays (days) {
      this.selectedLastDay = days

      let today = new Date()
      let DateChanged = new Date(today.setDate(today.getDate() - days))

      this.range = { start: new Date(DateChanged), end: new Date() }
    },

    handleDateFilter () {
      const startDate = this.range.start
      const endDate = this.range.end

      // Calcula a diferença em dias
      const diffDays = this.getDiffDays(startDate, endDate)
      logger.debug('diffDays: ', diffDays)
      const filteredDate = this.DateFilter(startDate, endDate)

      let dateFiltered = { selectedLastDay: diffDays, dateStart: filteredDate.start, dateEnd: filteredDate.end, pageNumber: 1 }
      this.closeModalsidebar()
      return this.$emit('changeDay', dateFiltered)
    },

    DateFilter (start, end) {
      let startDate = format(start, 'dd.MM.yyyy')
      let endDate = format(end, 'dd.MM.yyyy')

      return { start: startDate, end: endDate }
    },

    getDiffDays (startDate, endDate) {
      const oneDay = 24 * 60 * 60 * 1000
      const start = new Date(startDate)
      const end = new Date(endDate)

      // Ajustar horário de verão
      const offset = end.getTimezoneOffset() - start.getTimezoneOffset()
      start.setMinutes(start.getMinutes() - offset)

      return Math.round(Math.abs((end - start) / oneDay))
    },
  },
}
</script>

<template>
  <modal :active="active" :sideBar="'right'" @close="closeModalsidebar">
    <template v-if="active">
      <div :class="$classes['modal-content']">
        <span :class="$classes['modal-header-title']">Filtro</span>
        <h5>Últimos períodos</h5>
        <div :class="$classes['modal-button-wrapper']">
          <template v-for="(menuItem, index) in menu">
            <action :key="index"
              :class="{ [$classes['modal-button-filter']]: true, [$classes['active']]: menuItem.value === selectedLastDay }"
              @click="changeLastDays(menuItem.value)">
              <span :class="$classes['modal-icon-wrapper']">{{ menuItem.day }}</span>
            </action>
          </template>
        </div>
        <h5>Escolha uma data específica</h5>
        <div :class="$classes['modal-input-date-wrapper']">
          <v-date-picker v-model="range" is-range>
            <template v-slot="{ inputValue, inputEvents }">
              <div :class="$classes['modal-input-date-content']">
                <div :class="$classes['modal-input-date']">
                  <span>Data inicial</span>
                  <input :class="$classes['modal-input-date-btn']" :value="inputValue.start" v-on="inputEvents.start"
                    class="border w-32 rounded focus:outline-none focus:border-indigo-300" />
                </div>
                <icon v-if="!$media.mobile" name="long-arrow-alt-right"
                  :class="[$classes['modal-icon-wrapper'], $classes['icon-arrow']]" />
                <div :class="$classes['modal-input-date']">
                  <span>Data final</span>
                  <input :class="$classes['modal-input-date-btn']" :value="inputValue.end" v-on="inputEvents.end"
                    class="border w-32 rounded focus:outline-none focus:border-indigo-300" />
                </div>
              </div>
            </template>
          </v-date-picker>
        </div>
        <div :class="[$classes['modal-button-wrapper'], $classes['submit']]">
          <action variant="outlined" color="primary" uppercase bold @click="closeModalsidebar">
            Cancelar
          </action>
          <action variant="contained" color="primary" @click="handleDateFilter">
            Aplicar
          </action>
        </div>
      </div>
    </template>
  </modal>
</template>

<style module>
.modal-content {
  padding: 80px;
  display: flex;
  flex-direction: column;
}

.modal-header-title {
  font-size: 35px;
  font-weight: 600;
  margin-bottom: 20px;
}

.modal-button-filter {
  background-color: #F5F5F5;
  border: 1px solid #8f8b8b38;
  border-radius: 30px;
}

.modal-button-filter.active {
  background-color: #292929;
  color: #fff;
  border: 1px solid #8f8b8b10;
  border-radius: 30px;
}

.modal-button-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: 1rem 0rem 2rem 0rem;
  gap: 10px;
}

.modal-button-wrapper.submit {
  justify-content: center;
  gap: 20px;
}

.modal-icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 42px;
  flex-shrink: 0;
  padding: 4px 16px;
  font-size: 14px;
}

.modal-icon-wrapper.modal-icon {
  color: transparent;
  margin-right: 2px;
  stroke: #8f8b8b;
  stroke-width: 5px;
}

.modal-icon-wrapper.icon-arrow {
  color: #292929;
  width: 30px;
  padding: 0;
  margin-top: 21px;
  stroke: #8f8b8b;
  stroke-width: 5px;
}

.modal-icon-wrapper.icon-new {
  color: #292929;
  width: 60px;
  height: 60px;
  padding: 0;
  margin-top: 21px;
  stroke: black;
  stroke-width: 2px;
  fill: none;
}

.modal-input-date-wrapper {
  display: inherit;
  margin: 1rem 0;
  font-size: 14px;
  justify-content: flex-start;
}

.modal-input-date-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  width: 480px;
}

.modal-input-date {
  display: flex;
  flex-direction: column;
}

.modal-input-date-btn {
  width: 170px;
  height: 48px;
  background: url('~@/assets/icons/calendar.svg') no-repeat 2px center;
  background-size: 16%;
  padding: 4px 8px 4px 35px;
  border-radius: var(--input-border-radius);
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  position: relative;
}

@media (max-width: 768px) {
  .modal-content {
    padding: 30px;
    display: flex;
    flex-direction: column;
  }

  .modal-input-date-content {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    width: auto;
  }
}
</style>
